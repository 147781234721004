import { qs, qsa } from '../../resources/js/utils/dom'

window.addEventListener('click', (event) => {
	if (event.target.closest('.accordion-image-item--title')) {
		if (qs('.image-title') && event.target.closest('.accordion-active')) {
			event.target.closest('[data-accordion-item]').classList.remove('accordion-active')
		} else {
			for (const item of qsa("[data-accordion-item].accordion-active")) {
				item.classList.remove('accordion-active')
			}

			for (const item of qsa("[data-accordion-item=" + CSS.escape(event.target.closest('.accordion-image-item--title').id) + "]")) {
				item.classList.add('accordion-active')
			}
		}
	}
});
